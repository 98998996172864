import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './style/common.scss';
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false
Vue.use(BaiduMap, {
  ak: "Gz4cuoav9bVd5xA2ETMQoYag7FctqzfM"
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
