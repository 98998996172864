import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
  path: "/",
  name: "index",
  component: resolve => (require(['../views/index'], resolve)),
},{
  path: "/intro",
  name: "intro",
  component: resolve => (require(['../views/intro'], resolve)),
},{
  path: "/contact",
  name: "contact",
  component: resolve => (require(['../views/contact'], resolve)),
}]
const router = new VueRouter({
  mode: 'history', //去掉url中的#
  //配置路由和组件之间的映射关系
  routes,
})

export default router
//将router对象传入到vue实例中